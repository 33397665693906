.hero
  margin-top: 32px

.hero__content-wrapper
  position: relative
  display: grid
  grid-template-columns: 456px 298px 422px
  grid-template-rows: 63px 406px 176px
  background-color: transparent
  color: $white
  border-radius: 20px
  border: 1px solid $white
  
.hero-grid
  grid-column: 1/5
  grid-row: 1/2
  display: grid
  align-items: center
  grid-template-columns: 456px 298px 422px
  background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%)
  border-radius: 20px 20px 0px 0px

.hero-grid__item
  padding: 15px 0
  text-align: center
  font-size: 20px
  font-weight: 600
  text-transform: uppercase
  border-right: 1px solid $white
  border-bottom: 1px solid $white

  &:last-of-type
    border-right: 0
  
.hero__content
  grid-column: 1/3
  grid-row: 2/5
  padding: 43px 43px 49px 54px
  border-right: 1px solid $white
  
.hero__title
  font-size: 55px
  font-weight: 300
  line-height: 60px

  span
    font-weight: 500

.representatives
  position: relative
  display: flex
  align-items: flex-start
  // grid-template-columns: 248px 169px 169px
  column-gap: 18px
  width: 623px
  top: 40px
  margin-bottom: -85px

.representative
  position: relative
  width: 169px
  min-height: 170px
  border-radius: 20px
  overflow: hidden
  z-index: 0
  cursor: pointer
  transition: 0.3s

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    background: url('../img/contents/bg-representatives-sm.png') no-repeat center
    background-size: cover
    z-index: -1

  &:first-of-type
    // min-height: 304px
    &::before
      background: url('../img/contents/bg-representatives-lrg.png') no-repeat

  &--active
    width: 250px
    height: 305px

    &::after
      content: ''
      position: absolute
      top: 10px
      left: 20px
      width: 40px
      height: 40px
      background: url('../img/icon/icon-arrow-p.svg') no-repeat center
      z-index: 1
      
    .representative__info
      display: block

      
.representative__pic
  position: absolute
  width: 100%
  height: 100%
  object-fit: cover
  object-position: top
  bottom: -1px

.representative__info
  position: absolute
  padding: 30px 21px 21px
  bottom: 0
  font-size: 14px
  font-weight: 600
  line-height: 19px
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.56) 50.67%, rgba(0, 0, 0, 0.50) 100%)
  display: none

.representative__position
  font-weight: 300

.hero__game-content
  position: relative
  border-bottom: 1px solid $white

.hero__game
  position: absolute
  top: 27px
  right: -16px
  display: flex
  flex-wrap: wrap
  justify-content: center
  width: 453px
  height: 357px
  padding: 78px 0
  font-size: 49px
  font-weight: 600
  line-height: 58px
  text-align: center
  border: 1.351px solid rgba(255, 192, 192, 0.75)
  border-radius: 27.482px
  background: linear-gradient(138deg, #FF4F4F 23.51%, #FA1515 99.12%)
  box-shadow: 29px 27px 65.8px 0px rgba(0, 0, 0, 0.56), 43px 43px 60.6px 0px rgba(255, 72, 72, 0.22)
  transform: rotate(3.947deg)

  span
    align-self: flex-start
    display: flex
    width: auto
    padding: 8px 28px
    margin-bottom: 10px
    font-size: 48px
    font-weight: 500
    text-transform: uppercase
    color: #FF4747
    background-color: $white
    border-radius: 13px
  
.hero__link
  grid-column: 3/5
  align-self: center
  width: 350px

@media (max-width: $mobile)
  .hero
    margin-top: 12px

  .hero__content-wrapper
    position: relative
    display: flex
    flex-direction: column
    border-radius: 20px
    border: 1px solid $white
    
  .hero-grid
    display: flex
    background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%)
    border-radius: 20px 20px 0px 0px

  .hero-grid__item
    padding: 10px
    text-align: center
    font-size: 14px
    border: none

    &:first-of-type
      flex: 1 2 auto
      border-right: 1px solid $white

    &:last-of-type
      display: none
    
  .hero__content
    padding: 25px 17px
    border-right: 0
    border-bottom: 1px solid $white
    
  .hero__title
    width: 100%
    font-size: 30px
    line-height: 34px

  .representatives
    position: relative
    display: flex
    align-items: flex-end
    column-gap: 8px
    width: auto
    top: 0
    margin-top: 30px
    margin-bottom: 0

  .representative
    width: 92px
    min-height: 90px
    flex: 1 1 auto

    &--active
      width: 165px
      height: 165px

      &::after
        top: 10px
        left: 10px
        width: 20px
        height: 20px
        background-size: cover

  .representative__info
    position: absolute
    padding: 10px 5px 10px
    bottom: 0
    font-size: 10px
    line-height: 12px

  .representative__position
    margin-top: 3px

  .hero__game-content
    position: relative
    border-bottom: 1px solid $white

  .hero__game
    position: static
    width: 100%
    height: 208px
    padding: 31px 0
    font-size: 34px
    line-height: 41px

    span
      align-self: flex-start
      display: flex
      width: auto
      padding: 8px 28px
      margin-bottom: 10px
      font-size: 48px
      font-weight: 500
      text-transform: uppercase
      color: #FF4747
      background-color: $white
      border-radius: 13px
    
  .hero__link
    margin: 35px 0
    width: 350px


