/* Fonts */
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  src: local("ProximaNova-Regular"), local("Proxima Nova Regular"), url("../fonts/ProximaNova-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 600;
  src: local("ProximaNova-Semibold"), local("Proxima Nova Semibold"), url("../fonts/ProximaNova-Semibold.woff2") format("woff2");
}
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 700;
  src: local("ProximaNova-Bold"), local("Proxima Nova Bold"), url("../fonts/ProximaNova-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "Graphik LCG";
  src: local("Graphik LCG Light"), local("GraphikLCG-Light"), url("../fonts/GraphikLCG-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: local("Graphik LCG Regular"), local("GraphikLCG-Regular"), url("../fonts/GraphikLCG-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: local("Graphik LCG Medium"), local("GraphikLCG-Medium"), url("../fonts/GraphikLCG-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: local("Graphik LCG Semibold"), local("GraphikLCG-Semibold"), url("../fonts/GraphikLCG-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: local("Graphik LCG Bold"), local("GraphikLCG-Bold"), url("../fonts/GraphikLCG-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
/*  */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  min-width: 1200px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  min-height: 100%;
}
@media (max-width: 430px) {
  html {
    min-width: 375px;
  }
}

body {
  position: relative;
  height: 100%;
  margin: 0;
  font-family: "Graphik LCG", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
  background-color: #191919;
}

main {
  overflow: hidden;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1, h2, h3, h4 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

input {
  font-family: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 1200px;
  padding: 0 10px;
  margin: 0 auto;
}
@media (max-width: 430px) {
  .container {
    width: 95%;
    padding: 0;
  }
}

.visually-hidden:not(:focus):not(:active),
input[type=checkbox].visually-hidden,
input[type=radio].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.title {
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  text-align: center;
  color: #2F3441;
}
@media (max-width: 430px) {
  .title {
    font-size: 28px;
    line-height: 35px;
  }
}

.button-basic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 415px;
  height: 63px;
  margin: 0 auto;
  font-family: inherit;
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  transition: 0.3s;
}
.button-basic--white {
  color: #000000;
  background-color: #EBEBEB;
}
.button-basic--white:hover {
  background-color: #ffffff;
}
.button-basic--red {
  background-color: #F1361D;
}
@media (max-width: 430px) {
  .button-basic {
    width: auto;
    height: 50px;
    font-size: 16px;
  }
}

.button-border {
  color: #191919;
  border: 1px solid #191919;
}
.button-border:hover {
  color: #ffffff;
  background-color: #191919;
}
.button-border--white {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
}
.button-border--white:hover {
  color: #ffffff;
  background-color: transparent;
}

.slider-btn__prev,
.slider-btn__next {
  width: 65px;
  height: 100%;
  cursor: pointer;
  z-index: 99;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}
.slider-btn__prev.swiper-button-disabled,
.slider-btn__next.swiper-button-disabled {
  opacity: 0.5;
}

.slider-btn__prev {
  background: url("../img/icon/icon-arrow-slider-right.svg") no-repeat center;
  background-size: contain;
}

.slider-btn__next {
  background: url("../img/icon/icon-arrow-slider-left.svg") no-repeat center;
  background-size: contain;
}

@media (max-width: 430px) {
  .slider-btn__prev,
  .slider-btn__next {
    width: 35px;
  }
}

.header {
  display: flex;
  flex-direction: column;
}

.header__auth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 26px;
  border-bottom: 1px solid #D8D8D8;
  background-color: #ffffff;
}

.header__logo {
  padding-left: 18px;
  height: 14px;
}

.header__signin {
  line-height: 0;
}

.header__navigation {
  margin-top: 26px;
}

.navigation {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 66px;
}

.navigation__logo-link {
  display: block;
  width: 134px;
  height: 100%;
}

.navigation__logo {
  width: 100%;
  height: 100%;
}

.navigation__link {
  margin-left: auto;
  font-size: 14px;
  font-weight: 500;
  color: inherit;
}

.navigation__phone {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
}
.navigation__phone span {
  display: block;
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 430px) {
  .header--menu-active {
    background-color: rgba(34, 34, 34, 0.95);
  }
  .header--menu-active .header__auth {
    background-color: #ffffff;
  }
  .header--menu-active .navigation__logo {
    display: none;
  }
  .header--menu-active .navigation__logo--mobile {
    display: block;
  }
  .navigation {
    flex-wrap: wrap;
    min-height: auto;
    color: #ffffff;
  }
  .navigation__logo {
    width: 108px;
    height: 54px;
  }
  .show-mobile-menu {
    display: block;
  }
  .navigation__list {
    flex-direction: column;
    width: 100%;
    padding-bottom: 30px;
    display: none;
  }
  .navigation__link {
    padding: 0;
  }
  .navigation__link:hover {
    background-color: transparent;
    opacity: 1;
    padding-left: 5px;
  }
}
.hero {
  margin-top: 32px;
}

.hero__content-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 456px 298px 422px;
  grid-template-rows: 63px 406px 176px;
  background-color: transparent;
  color: #ffffff;
  border-radius: 20px;
  border: 1px solid #ffffff;
}

.hero-grid {
  grid-column: 1/5;
  grid-row: 1/2;
  display: grid;
  align-items: center;
  grid-template-columns: 456px 298px 422px;
  background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%);
  border-radius: 20px 20px 0px 0px;
}

.hero-grid__item {
  padding: 15px 0;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  border-right: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}
.hero-grid__item:last-of-type {
  border-right: 0;
}

.hero__content {
  grid-column: 1/3;
  grid-row: 2/5;
  padding: 43px 43px 49px 54px;
  border-right: 1px solid #ffffff;
}

.hero__title {
  font-size: 55px;
  font-weight: 300;
  line-height: 60px;
}
.hero__title span {
  font-weight: 500;
}

.representatives {
  position: relative;
  display: flex;
  align-items: flex-start;
  column-gap: 18px;
  width: 623px;
  top: 40px;
  margin-bottom: -85px;
}

.representative {
  position: relative;
  width: 169px;
  min-height: 170px;
  border-radius: 20px;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  transition: 0.3s;
}
.representative::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: url("../img/contents/bg-representatives-sm.png") no-repeat center;
  background-size: cover;
  z-index: -1;
}
.representative:first-of-type::before {
  background: url("../img/contents/bg-representatives-lrg.png") no-repeat;
}
.representative--active {
  width: 250px;
  height: 305px;
}
.representative--active::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 20px;
  width: 40px;
  height: 40px;
  background: url("../img/icon/icon-arrow-p.svg") no-repeat center;
  z-index: 1;
}
.representative--active .representative__info {
  display: block;
}

.representative__pic {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  bottom: -1px;
}

.representative__info {
  position: absolute;
  padding: 30px 21px 21px;
  bottom: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.56) 50.67%, rgba(0, 0, 0, 0.5) 100%);
  display: none;
}

.representative__position {
  font-weight: 300;
}

.hero__game-content {
  position: relative;
  border-bottom: 1px solid #ffffff;
}

.hero__game {
  position: absolute;
  top: 27px;
  right: -16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 453px;
  height: 357px;
  padding: 78px 0;
  font-size: 49px;
  font-weight: 600;
  line-height: 58px;
  text-align: center;
  border: 1.351px solid rgba(255, 192, 192, 0.75);
  border-radius: 27.482px;
  background: linear-gradient(138deg, #FF4F4F 23.51%, #FA1515 99.12%);
  box-shadow: 29px 27px 65.8px 0px rgba(0, 0, 0, 0.56), 43px 43px 60.6px 0px rgba(255, 72, 72, 0.22);
  transform: rotate(3.947deg);
}
.hero__game span {
  align-self: flex-start;
  display: flex;
  width: auto;
  padding: 8px 28px;
  margin-bottom: 10px;
  font-size: 48px;
  font-weight: 500;
  text-transform: uppercase;
  color: #FF4747;
  background-color: #ffffff;
  border-radius: 13px;
}

.hero__link {
  grid-column: 3/5;
  align-self: center;
  width: 350px;
}

@media (max-width: 430px) {
  .hero {
    margin-top: 12px;
  }
  .hero__content-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 1px solid #ffffff;
  }
  .hero-grid {
    display: flex;
    background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%);
    border-radius: 20px 20px 0px 0px;
  }
  .hero-grid__item {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    border: none;
  }
  .hero-grid__item:first-of-type {
    flex: 1 2 auto;
    border-right: 1px solid #ffffff;
  }
  .hero-grid__item:last-of-type {
    display: none;
  }
  .hero__content {
    padding: 25px 17px;
    border-right: 0;
    border-bottom: 1px solid #ffffff;
  }
  .hero__title {
    width: 100%;
    font-size: 30px;
    line-height: 34px;
  }
  .representatives {
    position: relative;
    display: flex;
    align-items: flex-end;
    column-gap: 8px;
    width: auto;
    top: 0;
    margin-top: 30px;
    margin-bottom: 0;
  }
  .representative {
    width: 92px;
    min-height: 90px;
    flex: 1 1 auto;
  }
  .representative--active {
    width: 165px;
    height: 165px;
  }
  .representative--active::after {
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    background-size: cover;
  }
  .representative__info {
    position: absolute;
    padding: 10px 5px 10px;
    bottom: 0;
    font-size: 10px;
    line-height: 12px;
  }
  .representative__position {
    margin-top: 3px;
  }
  .hero__game-content {
    position: relative;
    border-bottom: 1px solid #ffffff;
  }
  .hero__game {
    position: static;
    width: 100%;
    height: 208px;
    padding: 31px 0;
    font-size: 34px;
    line-height: 41px;
  }
  .hero__game span {
    align-self: flex-start;
    display: flex;
    width: auto;
    padding: 8px 28px;
    margin-bottom: 10px;
    font-size: 48px;
    font-weight: 500;
    text-transform: uppercase;
    color: #FF4747;
    background-color: #ffffff;
    border-radius: 13px;
  }
  .hero__link {
    margin: 35px 0;
    width: 350px;
  }
}
.editor-invites {
  margin-top: 173px;
  padding-bottom: 82px;
}

.editor-invites__title {
  font-size: 65px;
  font-weight: 500;
  line-height: 70px;
  text-align: center;
}

.editor {
  position: relative;
  margin-top: 55px;
  padding: 66px 61px 66px;
  border-radius: 20px;
  border: 1px solid #FFF;
  overflow: hidden;
}

.editor__pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 500px;
  height: 100%;
  object-fit: cover;
}

.editor__message {
  width: 542px;
  margin-left: auto;
}

.editor__message-text {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.editor__info {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.editor__info span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}

.editor-invites__link {
  margin: 40px auto 0;
}

@media (max-width: 430px) {
  .editor-invites {
    margin-top: 70px;
    padding-bottom: 51px;
  }
  .editor-invites__title {
    font-size: 35px;
    line-height: 40px;
  }
  .editor {
    margin-top: 45px;
    padding: 0;
  }
  .editor__pic {
    position: static;
    width: 100%;
    height: auto;
  }
  .editor__message {
    width: auto;
    padding: 26px 17px 49px;
  }
  .editor__message-text {
    font-size: 16px;
    line-height: 22px;
  }
  .editor__info {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .editor__info span {
    display: block;
    font-size: 18px;
    font-weight: 600;
  }
  .editor-invites__link {
    margin: 40px auto 0;
  }
}
.event-program {
  padding: 86px 0 84px;
  background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%);
}

.event-program__about {
  display: flex;
  align-items: flex-end;
  font-weight: 500;
}

.event-program__title {
  width: 500px;
  font-size: 65px;
  font-weight: 500;
  line-height: 70px;
}

.event-date {
  display: grid;
  grid-template-columns: 70px 156px;
  align-items: center;
  margin-left: auto;
  margin-right: 40px;
  margin-bottom: 10px;
  font-size: 23px;
}

.event-date__day {
  grid-column: 1/2;
  grid-row: 1/3;
  font-size: 82px;
  font-weight: 300;
}

.event-date__month,
.event-date__time {
  grid-column: 2/3;
}

.event-address {
  width: 274px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
}

.event-program__table {
  margin-top: 47px;
  border-collapse: separate;
  border-spacing: 0 33px;
}
.event-program__table tr .event:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.event-program__table tr .event:last-child {
  position: relative;
  width: 360px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.event-program__table tr td:first-child {
  width: 400px;
}
.event-program__table tr td:nth-child(2) {
  padding-left: 70px;
  padding-right: 50px;
}
.event-program__table tr:nth-child(2) .event__title {
  transform: rotate(4.27deg);
}

.event {
  padding: 20px 0 20px 17px;
  background-color: #ffffff;
}

.event__title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 240px;
  padding: 10px 24px;
  font-size: 30px;
  line-height: 34px;
  font-weight: 500;
  text-align: center;
  border: 1.18px solid rgba(255, 192, 192, 0.75);
  background: linear-gradient(138deg, #FF4F4F 23.51%, #FA1515 99.12%);
  border-radius: 24.005px;
  transform: rotate(-4.27deg);
}

.event__speaker {
  font-size: 22px;
  line-height: 26px;
  font-weight: 300;
  color: #000000;
}
.event__speaker span {
  display: block;
  font-weight: 600;
}

.event__speaker-pic {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 430px) {
  .event-program {
    padding: 54px 0 37px;
  }
  .event-program__about {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    font-weight: 500;
  }
  .event-program__title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }
  .event-date {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 156px;
    margin: 0;
    font-size: 18px;
    line-height: 22px;
  }
  .event-date__day {
    font-size: 18px;
    font-weight: 500;
  }
  .event-date__month {
    margin-left: 5px;
  }
  .event-address {
    width: 210px;
    margin-left: auto;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    font-style: normal;
  }
  .event-program__table {
    margin-top: 0;
    border-collapse: separate;
    border-spacing: 0 33px;
  }
  .event-program__table tr {
    display: flex;
    flex-direction: column;
  }
  .event-program__table tr .event:first-child {
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
  }
  .event-program__table tr .event:last-child {
    position: relative;
    width: 100%;
    height: 374px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    padding: 0;
    margin-bottom: 20px;
  }
  .event-program__table tr td:first-child {
    padding-top: 23px;
    width: 100%;
  }
  .event-program__table tr td:nth-child(2) {
    padding: 25px;
  }
  .event-program__table tr:nth-child(2) .event__title {
    transform: rotate(4.27deg);
  }
  .event-program__table tr td {
    width: 100%;
  }
  .event {
    padding: 0;
    margin-top: -1px;
    background-color: #ffffff;
  }
  .event__title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 169px;
    padding: 0 24px;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    border: 1.18px solid rgba(255, 192, 192, 0.75);
    background: linear-gradient(138deg, #FF4F4F 23.51%, #FA1515 99.12%);
    border-radius: 24.005px;
    transform: rotate(-4.27deg);
  }
  .event__speaker-pic {
    top: 2px;
    object-fit: cover;
    border-radius: 20px;
  }
}
.participant-kit {
  padding: 104px 0 84px;
}

.participant-kit__title {
  font-size: 65px;
  font-weight: 500;
  line-height: 83px;
}

.participant-kit__content {
  margin-top: 28px;
}

.participant-kit__grid {
  display: none;
}

.participant-kit__table {
  width: 100%;
  border-collapse: collapse;
  color: #000000;
  background-color: #ffffff;
  border-radius: 27px;
}
.participant-kit__table tr:first-child td {
  padding-top: 38px;
}
.participant-kit__table tr:last-child td {
  padding-bottom: 34px;
}

.participant-kit__data {
  border: 1px solid #000;
  padding: 19px 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}
.participant-kit__data:first-child {
  width: 429px;
}
.participant-kit__data:last-child {
  max-width: 421px;
}

.participant-kit__pice {
  font-size: 69px;
  font-weight: 500;
  line-height: 55px;
}
.participant-kit__pice span {
  display: block;
  font-size: 39px;
  color: #A9A9A9;
  text-decoration-line: line-through;
  text-decoration-thickness: 2px;
}

.participant-kit__note {
  margin-top: 26px;
  font-size: 16px;
  line-height: 20px;
}
.participant-kit__note span {
  display: block;
}
.participant-kit__note a {
  color: #ffffff;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.participant-kit__bill {
  width: 349px;
}

@media (max-width: 430px) {
  .participant-kit {
    padding-top: 70px;
  }
  .participant-kit__title {
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }
  .participant-kit__table {
    display: none;
  }
  .participant-kit__grid {
    display: block;
    color: #000000;
    background-color: #ffffff;
    border-radius: 27px;
    padding-bottom: 36px;
  }
  .participant-kit__elem {
    padding: 20px 26px;
    border-bottom: 1px solid #000;
  }
  .participant-kit__pice {
    padding: 20px 26px 29px;
    font-size: 50px;
    line-height: 55px;
    border-bottom: 1px solid #000;
  }
  .participant-kit__pice span {
    font-size: 30px;
  }
  .participant-kit__bill {
    width: auto;
    margin: 29px 31px 0;
  }
  .participant-kit__note {
    font-size: 14px;
    line-height: 14px;
  }
}
.reviews {
  background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%);
  padding: 87px 0 70px;
}

.reviews__title {
  width: 700px;
  margin: 0 auto;
  font-size: 65px;
  font-weight: 500;
  line-height: 70px;
  text-align: center;
}

.reviews__slider-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 66px;
}

.reviews__slider-list {
  display: flex;
}

.review {
  display: flex;
  align-items: center;
  width: 100%;
}

.review__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 20px;
  overflow: hidden;
  height: 240px;
  width: auto;
  opacity: 0.6;
}

.swiper-slide-active {
  width: 600px;
  height: auto;
  opacity: 1;
}

.reviews__slider-controls.slider-btn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 62px !important;
  margin: 0 auto;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: -55px !important;
}

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

.swiper-pagination-disabled > .swiper-pagination, .swiper-pagination.swiper-pagination-disabled {
  display: none !important;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
}

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #000);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet:only-child {
  display: none !important;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}

.swiper-pagination-vertical.swiper-pagination-bullets, .swiper-vertical > .swiper-pagination-bullets {
  right: var(--swiper-pagination-right, 8px);
  left: var(--swiper-pagination-left, auto);
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: var(--swiper-pagination-bullet-vertical-gap, 6px) 0;
  display: block;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: 0.2s transform, 0.2s top;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 0.2s transform, 0.2s left;
  background: #ffffff;
}

@media (max-width: 430px) {
  .reviews {
    background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%);
    padding: 66px 0 70px;
  }
  .reviews__title {
    width: auto;
    font-size: 32px;
    line-height: 36px;
  }
  .reviews__slider-wrapper {
    margin-top: 24px;
  }
  .review__item {
    height: 150px;
  }
  .swiper-slide-active {
    height: auto !important;
  }
}
.footer {
  color: #ffffff;
  background-color: #191919;
  border-top: 1px solid #ffffff;
}

.footer__wrapper {
  display: flex;
  align-items: flex-end;
  padding: 41px 0 80px 0;
  line-height: 16px;
}

.footer__copyright {
  margin: 0;
  font-size: 13px;
}

.footer__copyright-link {
  display: flex;
  flex-direction: column;
  display: inline;
  color: #ffffff;
  text-decoration: underline;
}
@media (max-width: 430px) {
  .footer__copyright-link {
    margin: 5px 0;
  }
}

.footer__policy {
  display: block;
  margin-top: 20px;
  text-decoration: underline;
  color: #ffffff;
}

.footer__callback {
  margin: 0;
  margin-left: 185px;
  font-size: 13px;
}

.footer__phone {
  display: block;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #ffffff;
}

.footer__logo-link {
  display: flex;
  margin-left: auto;
}

.footer__logo {
  width: 125px;
}

@media (max-width: 430px) {
  .footer {
    color: #ffffff;
  }
  .footer__wrapper {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 60px;
  }
  .footer__copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    font-size: 13px;
  }
  .footer__copyright span {
    display: block;
  }
  .footer__policy {
    margin-top: 10px;
    text-decoration: underline;
    color: #ffffff;
  }
  .footer__callback {
    margin: 10px 0 0 0;
    font-size: 13px;
  }
  .footer__phone {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    margin: 15px 0 0 0;
    padding-left: 48px;
  }
  .footer__phone::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    background: url("../img/icon/icon-phone.svg") no-repeat center;
  }
  .footer__logo-link {
    margin: 40px auto 0;
  }
}