.participant-kit
  padding: 104px 0 84px

.participant-kit__title
  font-size: 65px
  font-weight: 500
  line-height: 83px

.participant-kit__content
  margin-top: 28px

.participant-kit__grid
  display: none
  
.participant-kit__table
  width: 100%
  border-collapse: collapse
  color: $black
  background-color: $white
  border-radius: 27px

  tr:first-child
    td
      padding-top: 38px
  
  tr:last-child
    td
      padding-bottom: 34px
            
.participant-kit__data
  border: 1px solid #000
  padding: 19px 30px
  font-size: 20px
  font-weight: 500
  line-height: 24px

  &:first-child
    width: 429px

  &:last-child
    max-width: 421px

.participant-kit__pice
  font-size: 69px
  font-weight: 500
  line-height: 55px

  span
    display: block
    font-size: 39px
    color: #A9A9A9
    text-decoration-line: line-through
    text-decoration-thickness: 2px

.participant-kit__note
  margin-top: 26px
  font-size: 16px
  line-height: 20px

  span
    display: block

  a
    color: $white
    text-decoration: underline
    text-decoration-thickness: 1px
    text-underline-offset: 3px

.participant-kit__bill
  width: 349px

@media (max-width: $mobile)
  .participant-kit
    padding-top: 70px

  .participant-kit__title
    font-size: 35px
    line-height: 40px
    text-align: center

  .participant-kit__table
    display: none

  .participant-kit__grid
    display: block  
    color: $black
    background-color: $white
    border-radius: 27px
    padding-bottom: 36px

  .participant-kit__elem
    padding: 20px 26px
    border-bottom: 1px solid #000

  .participant-kit__pice
    padding: 20px 26px 29px
    font-size: 50px
    line-height: 55px
    border-bottom: 1px solid #000

    span
      font-size: 30px

  .participant-kit__bill
    width: auto
    margin: 29px 31px 0

  .participant-kit__note
    font-size: 14px
    line-height: 14px