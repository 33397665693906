.footer
  color: $white
  background-color: #191919
  border-top: 1px solid #ffffff

.footer__wrapper
  display: flex
  align-items: flex-end
  padding: 41px 0 80px 0
  line-height: 16px

.footer__copyright
  margin: 0
  font-size: 13px

.footer__copyright-link
  display: flex
  flex-direction: column
  display: inline
  color: $white
  text-decoration: underline

  @media (max-width: $mobile)

    margin: 5px 0

.footer__policy
  display: block
  margin-top: 20px
  text-decoration: underline
  color: $white
  
.footer__callback
  margin: 0
  margin-left: 185px
  font-size: 13px

.footer__phone
  display: block
  margin-top: 10px
  font-size: 18px
  font-weight: 600
  line-height: 22px
  color:  $white

.footer__logo-link
  display: flex
  margin-left: auto

.footer__logo
  width: 125px


@media ( max-width: $mobile)
  .footer
    color: $white

  .footer__wrapper
    flex-direction: column
    align-items: center
    text-align: center
    padding-bottom: 60px

  .footer__copyright
    display: flex
    flex-direction: column
    align-items: center
    margin: 0
    font-size: 13px

    span
      display: block

  .footer__policy
    margin-top: 10px
    text-decoration: underline
    color: $white
    
  .footer__callback
    margin: 10px 0 0 0
    font-size: 13px

  .footer__phone
    position: relative
    display: flex
    justify-content: center
    align-items: center
    height: 48px
    margin: 15px 0 0 0
    padding-left: 48px

    &::before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: 48px
      height: 48px
      background: url('../img/icon/icon-phone.svg') no-repeat center

  
  .footer__logo-link
    margin: 40px auto 0