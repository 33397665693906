.reviews
  background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%)
  padding: 87px 0 70px

.reviews__title
  width: 700px
  margin: 0 auto
  font-size: 65px
  font-weight: 500
  line-height: 70px
  text-align: center
  
.reviews__slider-wrapper
  position: relative
  display: flex
  width: 100%
  margin-top: 66px
  
.reviews__slider-list
  display: flex
  
.review
  display: flex
  align-items: center
  width: 100%
  
.review__item
  display: flex
  flex-direction: column
  justify-content: center
  flex-shrink: 0
  border-radius: 20px
  overflow: hidden
  height: 240px
  width: auto
  opacity: 0.6

.swiper-slide-active
  width: 600px
  height: auto
  opacity: 1

 
.reviews__slider-controls.slider-btn
  position: absolute
  top: 50%
  left: 0
  transform: translateY(-50%)
  display: flex
  justify-content: space-between
  width: 100%
  height: 62px !important
  margin: 0 auto

.swiper-pagination 
  position: absolute
  text-align: center
  transition: .3s opacity
  transform: translate3d(0,0,0)
  z-index: 10
  bottom: -55px !important

.swiper-pagination.swiper-pagination-hidden 
  opacity: 0

.swiper-pagination-disabled>.swiper-pagination,.swiper-pagination.swiper-pagination-disabled 
  display: none!important

.swiper-horizontal>.swiper-pagination-bullets,.swiper-pagination-bullets.swiper-pagination-horizontal,.swiper-pagination-custom,.swiper-pagination-fraction 
  bottom: var(--swiper-pagination-bottom,8px)
  top: var(--swiper-pagination-top,auto)
  left: 0
  width: 100%

.swiper-pagination-bullets-dynamic 
  overflow: hidden
  font-size: 0

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet 
  transform: scale(.33)
  position: relative

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active 
  transform: scale(1)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main 
  transform: scale(1)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev 
  transform: scale(.66)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev 
  transform: scale(.33)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next 
  transform: scale(.66)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next 
  transform: scale(.33)

.swiper-pagination-bullet 
  width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,8px))
  height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,8px))
  display: inline-block
  border-radius: var(--swiper-pagination-bullet-border-radius,50%)
  background: var(--swiper-pagination-bullet-inactive-color,#000)
  opacity: var(--swiper-pagination-bullet-inactive-opacity, .2)

button.swiper-pagination-bullet 
  border: none
  margin: 0
  padding: 0
  box-shadow: none
  -webkit-appearance: none
  appearance: none

.swiper-pagination-clickable .swiper-pagination-bullet 
  cursor: pointer

.swiper-pagination-bullet:only-child 
  display: none!important

.swiper-pagination-bullet-active 
  opacity: var(--swiper-pagination-bullet-opacity, 1)
  background: var(--swiper-pagination-color,var(--swiper-theme-color))

.swiper-pagination-vertical.swiper-pagination-bullets,.swiper-vertical>.swiper-pagination-bullets 
  right: var(--swiper-pagination-right,8px)
  left: var(--swiper-pagination-left,auto)
  top: 50%
  transform: translate3d(0px,-50%,0)

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,.swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet 
  margin: var(--swiper-pagination-bullet-vertical-gap,6px) 0
  display: block

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,.swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic 
  top: 50%
  transform: translateY(-50%)
  width: 8px

.swiper-pagination-vertical.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,.swiper-vertical>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet 
  display: inline-block
  transition: .2s transform,.2s top

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet 
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap,4px)

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic 
  left: 50%
  transform: translateX(-50%)
  white-space: nowrap

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet 
  transition: .2s transform,.2s left
  background: $white

@media (max-width: $mobile)
  .reviews
    background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%)
    padding: 66px 0 70px

  .reviews__title
    width: auto
    font-size: 32px
    line-height: 36px
    
  .reviews__slider-wrapper
    margin-top: 24px

      
  .review__item
    height: 150px

  .swiper-slide-active
    height: auto !important