
.header
  display: flex
  flex-direction: column

.header__auth
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  min-height: 26px
  border-bottom: 1px solid #D8D8D8
  background-color: $white

.header__logo
  padding-left: 18px
  height: 14px

.header__signin
  line-height: 0

// navigation
.header__navigation
  margin-top: 26px

.navigation
  display: flex
  align-items: center
  width: 100%
  min-height: 66px

.navigation__logo-link
  display: block
  width: 134px
  height: 100%

.navigation__logo
  width: 100%
  height: 100%

.navigation__link
  margin-left: auto
  font-size: 14px
  font-weight: 500
  color: inherit

.navigation__phone
  font-size: 20px
  font-weight: 700
  line-height: 20px

  span
    display: block
    font-size: 13px
    font-weight: 400
    
@media (max-width: $mobile)
  .header
    &--menu-active
      background-color: rgba(34,34,34, 0.95)

      .header__auth
        background-color: $white

      .navigation__logo
        display: none

        &--mobile
          display: block

    // .promo__content
    //   z-index: -1
  .navigation
    flex-wrap: wrap
    min-height: auto
    color: $white
    
  .navigation__logo
    width: 108px
    height: 54px

  .show-mobile-menu
    display: block

  .navigation__list
    flex-direction: column
    width: 100%
    padding-bottom: 30px
    display: none

  .navigation__link
    padding: 0

    &:hover
      background-color: transparent
      opacity: 1
      padding-left: 5px

      