/* Fonts */
// ProximaNova
@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 400
  src: local("ProximaNova-Regular"), local("Proxima Nova Regular"), url("../fonts/ProximaNova-Regular.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 600
  src: local("ProximaNova-Semibold"), local("Proxima Nova Semibold"), url("../fonts/ProximaNova-Semibold.woff2") format("woff2")

@font-face
  font-family: 'ProximaNova'
  font-style: normal
  font-weight: 700
  src: local("ProximaNova-Bold"), local("Proxima Nova Bold"), url("../fonts/ProximaNova-Bold.woff2") format("woff2")

// Graphik
@font-face 
  font-family: 'Graphik LCG'
  src: local('Graphik LCG Light'), local('GraphikLCG-Light'), url('../fonts/GraphikLCG-Light.woff2') format('woff2'),
  font-weight: 300
  font-style: normal

@font-face 
  font-family: 'Graphik LCG'
  src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'), url('../fonts/GraphikLCG-Regular.woff2') format('woff2'),
  font-weight: 400
  font-style: normal

@font-face 
  font-family: 'Graphik LCG'
  src: local('Graphik LCG Medium'), local('GraphikLCG-Medium'), url('../fonts/GraphikLCG-Medium.woff2') format('woff2'),
  font-weight: 500
  font-style: normal

@font-face 
  font-family: 'Graphik LCG'
  src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'), url('../fonts/GraphikLCG-Semibold.woff2') format('woff2'),
  font-weight: 600
  font-style: normal

@font-face 
  font-family: 'Graphik LCG'
  src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'), url('../fonts/GraphikLCG-Bold.woff2') format('woff2'),
  font-weight: 700
  font-style: normal
/*  */