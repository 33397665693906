.editor-invites
  margin-top: 173px
  padding-bottom: 82px

.editor-invites__title
  font-size: 65px
  font-weight: 500
  line-height: 70px
  text-align: center

.editor
  position: relative
  margin-top: 55px
  padding: 66px 61px 66px
  border-radius: 20px
  border: 1px solid #FFF
  overflow: hidden

.editor__pic
  position: absolute
  top: 0
  left: 0
  width: 500px
  height: 100%
  object-fit: cover

.editor__message
  width: 542px
  margin-left: auto

.editor__message-text
  font-size: 18px
  line-height: 22px
  margin-bottom: 20px

.editor__info
  font-size: 14px
  font-weight: 400
  line-height: 22px
  
  span
    display: block
    font-size: 18px
    font-weight: 600

.editor-invites__link
  margin: 40px auto 0

@media (max-width: $mobile)
  .editor-invites
    margin-top: 70px
    padding-bottom: 51px

  .editor-invites__title
    font-size: 35px
    line-height: 40px

  .editor
    margin-top: 45px
    padding: 0

  .editor__pic
    position: static
    width: 100%
    height: auto

  .editor__message
    width: auto
    padding: 26px 17px 49px

  .editor__message-text
    font-size: 16px
    line-height: 22px

  .editor__info
    font-size: 14px
    font-weight: 400
    line-height: 22px
    
    span
      display: block
      font-size: 18px
      font-weight: 600

  .editor-invites__link
    margin: 40px auto 0