
$white: #ffffff
$black: #000000
$basic-gray: #191919
$basic-red: #F1361D
$mobile: 430px

*,
*::before,
*::after
  box-sizing: inherit

html
  min-width: 1200px
  box-sizing: border-box
  scroll-behavior: smooth
  width: 100%
  min-height: 100%

  @media (max-width: $mobile)
    min-width: 375px

body
  position: relative
  height: 100%
  margin: 0
  font-family: 'Graphik LCG', "Arial", sans-serif
  font-size: 24px
  font-weight: 400
  line-height: 32px
  color: $white
  background-color: $basic-gray

main
  overflow: hidden

ul
  margin: 0
  padding: 0
  list-style-type: none

h1,h2,h3,h4
  margin: 0

p
  margin: 0

a
  text-decoration: none

input
  font-family: inherit

img
  max-width: 100%
  height: auto

.container
  width: 1200px
  padding: 0 10px
  margin: 0 auto

  @media (max-width: $mobile)
    width: 95%
    padding: 0

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden
  position: absolute
  width: 1px
  height: 1px
  margin: -1px
  border: 0
  padding: 0
  white-space: nowrap
  clip-path: inset(100%)
  clip: rect(0 0 0 0)
  overflow: hidden

.title
  margin: 0
  font-size: 40px
  font-weight: 600
  line-height: 54px
  text-align: center
  color: #2F3441

  @media (max-width: $mobile)
    font-size: 28px
    line-height: 35px

.button-basic
  display: flex
  justify-content: center
  align-items: center
  width: 415px
  height: 63px
  margin: 0 auto
  font-family: inherit
  font-size: 20px
  font-weight: 500
  color: $white
  border: none
  border-radius: 10px
  transition: 0.3s

  &--white
    color: $black
    background-color: #EBEBEB

    &:hover
      background-color: $white

  &--red
    background-color: $basic-red


   
  @media (max-width: $mobile)
    width: auto
    height: 50px
    font-size: 16px

.button-border
  color: $basic-gray
  border: 1px solid $basic-gray
  
  &:hover
    color: $white
    background-color: $basic-gray

  &--white
    color: $white
    background-color: transparent
    border: 1px solid $white

    &:hover
      color: $white
      background-color: transparent



.slider-btn__prev,
.slider-btn__next
  width: 65px
  height: 100%
  cursor: pointer
  z-index: 99
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))

  &.swiper-button-disabled
    opacity: 0.5

.slider-btn__prev
  background: url('../img/icon/icon-arrow-slider-right.svg') no-repeat center
  background-size: contain

.slider-btn__next
  background: url('../img/icon/icon-arrow-slider-left.svg') no-repeat center
  background-size: contain

.slider-btn__prev,
.slider-btn__next
  @media (max-width: $mobile) 
    width: 35px