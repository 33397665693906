.event-program
  padding: 86px 0 84px
  background: linear-gradient(120deg, #FD3434 10.88%, #FF4E4E 68.35%)

.event-program__about
  display: flex
  align-items: flex-end
  font-weight: 500

.event-program__title
  width: 500px
  font-size: 65px
  font-weight: 500
  line-height: 70px

.event-date
  display: grid
  grid-template-columns: 70px 156px
  align-items: center
  margin-left: auto
  margin-right: 40px
  margin-bottom: 10px
  font-size: 23px

.event-date__day
  grid-column: 1/2
  grid-row: 1/3
  font-size: 82px
  font-weight: 300

.event-date__month,
.event-date__time
  grid-column: 2/3

.event-address
  width: 274px
  margin-bottom: 10px
  font-size: 24px
  line-height: 28px
  font-style: normal

// table
.event-program__table
  margin-top: 47px
  border-collapse: separate
  border-spacing: 0 33px

  tr
    .event:first-child
      border-top-left-radius: 20px
      border-bottom-left-radius: 20px

    .event:last-child
      position: relative
      width: 360px
      border-top-right-radius: 20px
      border-bottom-right-radius: 20px

    td:first-child
      width: 400px

    td:nth-child(2)
      padding-left: 70px
      padding-right: 50px

    &:nth-child(2)
      .event__title
        transform: rotate(4.27deg)

.event
  padding: 20px 0 20px 17px
  background-color: $white
  
.event__title
  display: flex
  justify-content: center
  align-items: center
  min-height: 240px
  padding: 10px 24px
  font-size: 30px
  line-height: 34px
  font-weight: 500
  text-align: center
  border: 1.18px solid rgba(255, 192, 192, 0.75)
  background: linear-gradient(138deg, #FF4F4F 23.51%, #FA1515 99.12%)
  border-radius: 24.005px
  transform: rotate(-4.27deg)

.event__speaker
  font-size: 22px
  line-height: 26px
  font-weight: 300
  color: $black

  span
    display: block
    font-weight: 600

.event__speaker-pic
  position: absolute
  top: 0
  right: 0
  width: 100%
  height: 100%

@media (max-width: $mobile)  
  .event-program
    padding: 54px 0 37px

  .event-program__about
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    font-weight: 500

  .event-program__title
    width: 100%
    margin-bottom: 20px
    font-size: 35px
    line-height: 40px
    text-align: center

  .event-date
    display: flex
    flex-wrap: wrap
    align-items: center
    width: 156px
    margin: 0
    font-size: 18px
    line-height: 22px

  .event-date__day
    font-size: 18px
    font-weight: 500

  .event-date__month
    margin-left: 5px

  .event-address
    width: 210px
    margin-left: auto
    margin-bottom: 0
    font-size: 18px
    font-weight: 500
    line-height: 22px
    font-style: normal

  // table
  .event-program__table
    margin-top: 0
    border-collapse: separate
    border-spacing: 0 33px

    tr
      display: flex
      flex-direction: column

      .event:first-child
        border-top-right-radius: 20px
        border-bottom-left-radius: 0

      .event:last-child
        position: relative
        width: 100%
        height: 374px
        border-top-right-radius: 0
        border-bottom-left-radius: 20px
        padding: 0
        margin-bottom: 20px
        // background: transparent

      td:first-child
        padding-top: 23px
        width: 100%

      td:nth-child(2)
        padding: 25px

      &:nth-child(2)
        .event__title
          transform: rotate(4.27deg)

      td
        width: 100%

  .event
    padding: 0
    margin-top: -1px
    background-color: $white
    
  .event__title
    display: flex
    justify-content: center
    align-items: center
    min-height: 169px
    padding: 0 24px
    font-size: 30px
    font-weight: 500
    text-align: center
    border: 1.18px solid rgba(255, 192, 192, 0.75)
    background: linear-gradient(138deg, #FF4F4F 23.51%, #FA1515 99.12%)
    border-radius: 24.005px
    transform: rotate(-4.27deg)

  .event__speaker-pic
    // position: absolute
    // top: 0
    // right: 0
    // width: 100%
    // height: 374px
    top: 2px
    object-fit: cover
    border-radius: 20px